/**
 * Element properties
 */
ion-item-option {
  background: white;
}

/**
 * Custom modifiers
 */
