.login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  overflow-y: auto;
  padding: var(--gh-spacing);
  /* stylelint-disable */
  background: url('../images/login-bg.jpg') center center / cover no-repeat;
  /* stylelint-enable */
  box-shadow: inset 0 0 30vw rgba(0, 0, 0, .5);

  &__heading {
    text-align: center;

    h1 {
      margin: 0;
    }
  }

  &__logos {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & > * {
      max-width: 30%;
      max-height: 60px;
    }
  }

  &__button {
    margin-top: var(--gh-spacing);
  }

  // https://github.com/ionic-team/ionic/issues/15136
  .shadow-dom-not-submitting-workaround-button {
    position: absolute;
    left: -100vw;
    visibility: hidden;
  }

  & > * {
    flex-shrink: 0;
    margin: var(--gh-spacing) 0;
  }

  ion-button {
    margin: 0;
  }

  ion-list {
    --ion-item-background: 0;
    margin: 0;
  }

  ion-item {
    --ion-item-background: 0;
    --ion-item-background-focused: 0;
    --color: var(--ion-color-light-contrast);
    --color-focused: var(--ion-color-light);
    --color-activated: var(--ion-color-light);
    --highlight-color-focused: var(--ion-color-light);
    --ion-item-border-color: var(--ion-color-light);

    ion-input {
      color: var(--ion-color-light) !important;
    }

    &.item-has-focus {
      ion-label {
        color: var(--ion-color-light) !important;
      }

      ion-input {
        color: var(--ion-color-light) !important;
      }
    }
  }

  ion-icon {
    color: #FFF;
  }
}
