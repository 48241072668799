/**
 * Element properties
 */
ion-back-button {
  --color: var(--ion-color-secondary-contrast);

  &.md::part(text) {
    display: none;
  }
}

/**
 * Custom modifiers
 */
