/**
 * Element properties
 */

/**
 * Custom modifiers
 */
.list-item--padding-small {
  --padding-start: 10px;
}
