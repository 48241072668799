/**
 * Element properties
 */
ion-button {
  font-weight: var(--gh-font-weight--bold);
  text-transform: uppercase;
  letter-spacing: normal;
}

/**
 * Custom modifiers
 */
ion-button.ion-button--as-footer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.ion-button--margin-small {
  margin: 10px;
}
