/**
 * Element properties
 */
ion-title {
  font-weight: var(--gh-font-weight--semi-bold);
}

/**
 * Custom modifiers
 */
