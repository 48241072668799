.product-list {
  height: 100%;
}

.product-list--search-page :first-child > ion-item-sliding {
  margin-top: 60px;
}

.product {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: var(--gh-spacing) 0 var(--gh-spacing) calc(.5 * var(--gh-spacing));
  color: var(--gh-color-medium);

  &__specs {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__name {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__amount {
    flex-shrink: 0;
  }

  &__add,
  &__message {
    flex-shrink: 0;
    padding-left: var(--gh-spacing);
    margin-left: auto;
  }

  &__not-available {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(var(--ion-color-medium-rgb), .2);
    text-align: center;
    font-size: 12px;

    ion-icon {
      font-size: 26px;
      color: var(--ion-color-medium-tint);
    }
  }

  &--small {
    padding: calc(var(--gh-spacing) / 2) 0;
  }
}

.product-amount-buttons {
  $self: &;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 38px;

  &__expander {
    position: absolute;
    right: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 2px;
    transform: scaleX(0) translateX(40px);
    transform-origin: 100% 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms, transform 250ms;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: calc(100% + 38px);
      height: 100%;
      border-radius: 20px;
      background: var(--ion-color-primary);
      z-index: -1;
    }

    #{$self}--expanded & {
      z-index: 1;
      pointer-events: all;
      transform: scaleX(1);
      opacity: 1;
      transition: opacity 200ms 50ms, transform 300ms;
    }
  }

  &__button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    font-size: 16px;
    z-index: 1;
    transition: background .2s;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    ion-icon {
      font-size: 22px;
    }

    &--outline {
      background: var(--ion-color-primary-contrast);
      color: var(--ion-color-primary);
      border: solid 2px var(--ion-color-primary);
      font-weight: var(--gh-font-weight--semi-bold);
    }
  }

  &__amount {
    margin: 0 8px;
    min-width: 50px;
    height: 100%;

    .manual-quantity-selector {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      outline: none;
      background: rgba(var(--ion-color-light-rgb), .2);
      color: var(--ion-color-primary-contrast);
      font-weight: var(--gh-font-weight--semi-bold);
      font-size: 16px;
      padding: 0 10px;

      ion-icon {
        font-size: 26px;
        margin-right: 5px;
      }
    }
  }
}

.totals {
  --border-width: 0;
  --border-color: white;

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 58px;
  }
}

ion-item-option.product__remove {
  background: #eee;
}

.item-options-md.product__item-options {
  border-bottom-width: 1px;
}
