/**
 * Element properties
 */
ion-card {
  --color: var(--ion-color-dark);
}

/**
 * Custom modifiers
 */
