.category-list {
  height: 100%;
}

// this 16px is added on top of the regular size of the list items (see CategoryListComponent.tsx)
.first-child-spacing-fix {
  padding-top: var(--ion-padding, 16px);
}

.category-card {
  position: relative;
  height: 180px;
  margin: 0 var(--ion-padding, 16px) var(--ion-padding, 16px);

  & > a.ion-activatable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gh-color-black);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    opacity: .7;
  }

  h2 {
    position: relative;
    z-index: 2;
    color: var(--ion-color-light);
    text-align: center;
    hyphens: auto;
    padding: 10px;
  }

  &--favorites {
    & > a.ion-activatable {
      background:
        linear-gradient(
          rgba(0,0,0,.3),
          rgba(0,0,0,.3)
        ),
        url('../images/FavoriteProducts.png') center center / cover no-repeat;
    }
  }
}
