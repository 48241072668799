h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--gh-font-weight--bold);
}

.h1--large {
  font-size: 36px;
}

.small-note {
  color: var(--ion-color-light-shade);
  font-weight: var(--gh-font-weight--semi-bold);
  font-size: 12px;
}

.fat-note {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: var(--gh-font-weight--bold);
  color: var(--ion-color-light-shade);
}

.warning-text {
  display: block;
  border: solid 1px var(--ion-color-warning);
  background: rgba(var(--ion-color-warning-rgb), .2);
  color: var(--ion-color-warning);
  font-weight: var(--gh-font-weight--bold);
  text-align: center;
  padding: var(--gh-spacing);
  margin: var(--gh-spacing) 0 0;
  border-radius: 3px;
}
