/**
 * Element properties
 */
ion-list-header {
  font-weight: var(--gh-font-weight--bold);
  font-size: 18px;
  text-transform: none;
  letter-spacing: normal;
}


/**
 * Custom modifiers
 */
.list-header--padding-small {
  padding-left: 10px;
}
