/**
 * Element properties
 */


/**
 * Custom modifiers
 */

/**
 * Note:
 * You have to set the ion-activatable class to items that need the custom Ionic Ripple effect
 * https://forum.ionicframework.com/t/how-to-use-ion-ripple-effect/146000/7
 */
.ion-activatable {
  position: relative; // needed for the positioning of the ripple effect
  ion-ripple-effect {
    z-index: 10; // makes the ripple effect always on top
  }
}
