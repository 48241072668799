.order-list__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-list__date {
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 20px;
  }
}

.order-list__link {
  text-align: right;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}

.custom-list-header {
  display: block;
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
}

.my-order-flag {
  display: inline-block;
  background-color: var(--ion-color-secondary);
  border-radius: 4px;
  padding: 3px 4px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--ion-color-secondary-contrast);
}

.order-summary__info {
  margin: 20px 0;
  padding-bottom: 20px;
  text-transform: capitalize;
  line-height: 1.6;

  strong {
    text-transform: none;
  }
}

.order-summary__list-headings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: bold;
}
