/**
 * Element properties
 */
ion-toolbar {
  --background: var(--ion-color-secondary);
}

/**
 * Custom modifiers
 */
