.flexbox {
  display: flex;

  &--space-between {
    justify-content: space-between;
  }

  &--align-center {
    align-items: center;
  }
}

.align-self {
  &--start {
    align-self: flex-start;
  }

  &--end {
    align-self: flex-end;
  }
}

/**
 * gh-margin/padding
 * ion-margin-start (etc.) won't work on some devices, because the browser doesnt always support logical properties
 * This is to support RTL as well as LTR css. We use gh-margin until we are able to use ion-margin.
 */
.gh-margin {
  margin-top: var(--ion-margin, 16px);
  margin-right: var(--ion-margin, 16px);
  margin-bottom: var(--ion-margin, 16px);
  margin-left: var(--ion-margin, 16px);

  &-top {
    margin-top: var(--ion-margin, 16px);
  }

  &-right {
    margin-right: var(--ion-margin, 16px);
  }

  &-bottom {
    margin-bottom: var(--ion-margin, 16px);
  }

  &-left {
    margin-left: var(--ion-margin, 16px);
  }

  &-horizontal {
    margin-left: var(--ion-margin, 16px);
    margin-right: var(--ion-margin, 16px);
  }

  &-vertical {
    margin-top: var(--ion-margin, 16px);
    margin-bottom: var(--ion-margin, 16px);
  }
}

.gh-padding {
  padding-top: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);

  &-top {
    padding-top: var(--ion-padding, 16px);
  }

  &-right {
    padding-right: var(--ion-padding, 16px);
  }

  &-bottom {
    padding-bottom: var(--ion-padding, 16px);
  }

  &-left {
    padding-left: var(--ion-padding, 16px);
  }

  &-horizontal {
    padding-left: var(--ion-padding, 16px);
    padding-right: var(--ion-padding, 16px);
  }

  &-vertical {
    padding-top: var(--ion-padding, 16px);
    padding-bottom: var(--ion-padding, 16px);
  }
}
