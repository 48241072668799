/**
 * Element properties
 */

/**
 * Custom modifiers
 */
.ion-content--image-background {
  --background: transparent;
  background: white url('../images/OrderBackground.jpg') left bottom / contain no-repeat;
}

.ion-content--cardlist-background {
  --background: #fafafa;
}
