.cart-button {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 8px 0;
  color: var(--ion-color-light);

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 var(--gh-spacing);
  }

  &__amount {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--ion-color-light);
    color: var(--gh-color-black);
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    font-weight: var(--gh-font-weight--semi-bold);
    margin-right: 10px;
  }

  &__text {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: var(--gh-font-weight--bold);

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > :first-child {
      flex-shrink: 1;
      margin-right: 10px;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }
}


.cart-icon,
.search-icon {
  --border-radius: 0 !important;

  &__icon {
    position: relative;
    font-weight: bold;
    font-size: 2em;
  }

  &__amount {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 3px 6px;
    background: var(--ion-color-secondary);
    border-radius: 8px;
    font-weight: bold;
  }
}

.ios .cart-icon__amount {
  top: 1px;
  right: -4px;
}

.md .cart-icon__amount {
  top: -8px;
  right: -10px;
}
