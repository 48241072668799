/**
 * Element properties
 */
ion-label {
  --color: var(--ion-color-medium) !important;
}

/**
 * Custom modifiers
 */
