@function i($str) {
  @return unquote($str);
}

/**
 * Fonts
 */
@import 'font';

/**
 * Component styling
 *
 * Parent class '.md' added for more specificity, to be able to style Ionic's webcomponents the native way, via
 * custom properties. Some components like 'IonButton' don't need this parent class, but i.e. IonCard is styled
 * via '.sc-ion-card-md-h' (class) and not via 'ion-card' (custom element).
 */
.md, .ios {
  @import 'ionic/IonButton';
  @import 'ionic/IonBackButton';
  @import 'ionic/IonCard';
  //@import 'ionic/IonCardTitle';
  //@import 'ionic/IonCheckbox';
  //@import 'ionic/IonChip';
  @import 'ionic/IonContent';
  //@import 'ionic/IonFab';
  //@import 'ionic/IonFooter';
  //@import 'ionic/IonGrid';
  //@import 'ionic/IonIcon';
  @import 'ionic/IonItem';
  @import 'ionic/IonItemOption';
  @import 'ionic/IonLabel';
  @import 'ionic/IonListHeader';
  @import 'ionic/IonRippleEffect';
  //@import 'ionic/IonSearchbar';
  //@import 'ionic/IonSegmentButton';
  //@import 'ionic/IonSelect';
  //@import 'ionic/IonText';
  //@import 'ionic/IonTextarea';
  @import 'ionic/IonTitle';
  @import 'ionic/IonToolbar';
}

/**
 * Custom global styling
 */
@import 'custom/typography';
@import 'custom/layout';
@import 'custom/link-as-block';

/**
 * Custom component styling
 */
@import 'custom/category-card';
@import 'custom/product';
@import 'custom/header-buttons';
@import 'custom/login';
@import 'custom/order';

/**
 * Helpers
 */
@import 'custom/helpers';


/**
 * CSS properties
 * Disabled linter because it can't handle custom properties yet (hex declaration, property sort order etc.)
 */
// sass-lint:disable-all
:root {
  /**
   * Basic background / text contrasts (Stepped colors)
   * https://ionicframework.com/docs/theming/themes/#stepped-color-generator
   */

  // (...)

  /**
   * Theme variables with Groentehal colors
   * https://ionicframework.com/docs/theming/color-generator
   */
  --ion-color-primary: #5FBA57;
  --ion-color-primary-rgb: 95, 186, 87;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #54a44d;
  --ion-color-primary-tint: #6fc168;

  --ion-color-secondary: #F27957;
  --ion-color-secondary-rgb: 242, 121, 87;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d56a4d;
  --ion-color-secondary-tint: #f38668;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #5cb85c;
  --ion-color-success-rgb: 92, 184, 92;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #51a251;
  --ion-color-success-tint: #6cbf6c;

  --ion-color-warning: #e4b046;
  --ion-color-warning-rgb: 228, 176, 70;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c99b3e;
  --ion-color-warning-tint: #e7b859;

  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  --ion-color-dark: #222222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f0f0f0;
  --ion-color-light-rgb: 240, 240, 240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d3d3d3;
  --ion-color-light-tint: #f2f2f2;

  /**
   * Fonts
   */
  --ion-font-family: 'Source Sans Pro', sans-serif;

  /**
   * Custom- Custom Properties
   * Prefix with 'gh-' for easy code completion
   * Adding new colors? https://ionicframework.com/docs/theming/colors#new-color-creator
   */

  // colors
  //--gh-primary-color--tint: rgba(var(--ion-color-primary-rgb, .5));
  //--gh-background-color--tint: white;
  //--gh-background-color--shade: #f3f4f5;
  --gh-border-color: #D8D8D8;
  --gh-color-medium: #4A4A4A;
  --gh-color-black: #000;
  //--gh-gradient: linear-gradient(90deg, #FBB847, #1CE676);

  // typography
  --gh-font-weight--normal: 400;
  --gh-font-weight--semi-bold: 600;
  --gh-font-weight--bold: 700;

  // shadows
  //--gh-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .3);
  //--gh-box-shadow-inset--top: inset 0 9px 6px -7px rgba(0, 0, 0, .3);
  //--gh-box-shadow-inset--left: inset 9px 0 6px -7px rgba(0, 0, 0, .3);
  //--gh-footer-shadow: -4px 0 10px rgba(0, 0, 0, .2);

  // layout sizes
  //--gh-toolbar-min-height: 56px; // default value from ionic
  //--gh-expandable-footer-height: 80vh;
  --gh-spacing: 20px;
}

// sass-lint:enable-all
