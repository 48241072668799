.custom-search {
  width: 100%;
  height: 100%;

  &__bar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .searchbar-input-container {
      background: white;
    }
  }

  &__hint {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &__results {
    height: 100%;
  }
}
